import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';
import {AppModule} from './app/app.module';
import {environment} from './environments/environment';
import {isDevMode} from "@angular/core";
import * as Sentry from '@sentry/angular';

if (!isDevMode()) {
  const sentry = environment.errorTracker.sentry;
  Sentry.init({
    dsn: sentry.dsn,
    environment: window.location.host,
    enabled: !window.location.host.includes('localhost'),
    release: `${sentry.name}@${sentry.version}`,
    integrations: [
      Sentry.browserTracingIntegration({enableInp: true}),
      Sentry.browserProfilingIntegration(),
    ],
    tracesSampleRate: 1.0,
    profilesSampleRate: 1.0,
    tracePropagationTargets: ["localhost", /^https:\/\/[A-Za-z]+\.iotzen\.app/],
  });
}


// const activeTransaction = Sentry?.getActiveTransaction();
// const bootstrapSpan =
//   activeTransaction &&
//   activeTransaction?.startChild({
//     description: 'platform-browser-dynamic',
//     op: 'ui.angular.bootstrap',
//   });
//
// const bootstrap = () => ;
//
//
// if (!environment.production) {
//   if (module['hot']) {
//     hmrBootstrap(module, bootstrap);
//   } else {
//     console.error('HMR is not enabled for webpack-dev-server!');
//     console.log('Are you using the --hmr flag for ng serve?');
//   }
// } else {
platformBrowserDynamic().bootstrapModule(AppModule)
  .then(console.log)
  .catch(console.error)
  .finally(() => {
    console.log('AppModule is running');
  });
// }
